@font-face {
    font-family: 'Akatab';
    src: url('./fonts/Akatab/Akatab-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.Akatab {
    font-family: 'Akatab', sans-serif;
}

@font-face {
    font-family: 'Rock_3D';
    src: url('./fonts/Rock_3D/Rock3D-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.Rock_3D {
    font-family: 'rock_3D', sans-serif;
}

@font-face {
    font-family: 'circular';
    src: url('./fonts/Circular_Body/CircularStd-Book.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* body text */
.circular {
    font-family: 'circular';
}


/* body titles */
@font-face {
    font-family: 'mersad';
    src: url('./fonts/Mersad_Title/Mersad-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.mersad {
    font-family: 'mersad', sans-serif;
}

/* Lapture- is title accent font */