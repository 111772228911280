.footer-button {
    /* box-shadow: inset 0px 0px 0px 0px #ffffff; */
    width: auto;
    height: 50;
    scale: 1;
    border-radius: 40px;
    border: 0px solid #8bc743;
    background-color: #8bc743;
    color: #161e16;
    display: inline-block;
    cursor: pointer;
    font-family: 'mersad', Arial;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    padding: 12px 31px;
    margin-left: 26px;
    margin-top: -10px;
}

.email-button-wrap {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: row;
}

.footer-button:hover {
    border: 0.8px solid #addb72;
    background-color: #addb72;
    color: #161e16;
}

.footer {
    background-color: #161e17;
    color: #6b6b6b;
    padding-top: 50px;
    padding-bottom: 50px;
    height: auto;
    margin-top: auto;
    width: 100%;
    max-width: 100vw !important;
    margin-bottom: 0%;
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
}

@media (max-width:915px) {
    .footer {
        padding-left: 0px;
    }
}

.footer-left {
    flex: 1;
    justify-content: space-between;

}

.footer-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    max-width: 80vw;
}

.footer-links {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
}

.footer-links a {
    color: #6b6b6b;
    text-decoration: none;
    margin-bottom: 10px;
}

.footer h2 {
    font-family: 'mersad';
    font-size: 60px;
    color: #e7e7e7;
    margin-top: 3%;
    margin-left: 30px;
    line-height: 1.2;
}

.footer input {
    width: 325px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    border: 1px solid #addb72;
    border-radius: 20px;
    border-color: #b6b6b6;
    border-width: '8px' !important;
    background-color: transparent;
    font-family: 'circular';
    color: #ffffff;
}

@media (max-width:480px) {

    .footer {
        padding-top: 50px;
        padding-bottom: 10px;
        padding-right: 0px;
    }

    .footer h2 {
        text-align: center;
        margin-left: 0px;
    }

    .footer-right {
        max-width: 100vw;
        display: grid;
        justify-items: center;
        text-align: center;
        justify-content: center;
    }


    .footer-links {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-left: 0%;
        margin-right: auto;
    }

    .footer-links:nth-child(1) {
        font-size: 22px;
        padding-bottom: 10px;
        padding-top: 20px;
    }

    .footer-left {
        display: grid;
        justify-items: center;
        margin-left: 0px;
        margin-bottom: 30px;
        /* Stack children vertically */
        align-items: start;
        /* Align items to the start (left for LTR direction) */
        gap: 10px;
        /* Gap between children (supported in newer browsers) */
    }

    .footer input {
        width: calc(35%);
    }

    .footer-button {

        padding: 12px 31px;
        margin-top: 5px;
        margin-left: 0px;
        font-size: 15px;
    }

    .footer-left input {
        width: 90%;
        margin-left: 0px;
    }

    .email-button-wrap {
        display: contents;
    }

    /* You may also want to adjust styles for your input and button */

}